import "./styles/Dashboard.css";
import pro from "../Navbar/images/products.png";
import All from "./images/home.png";
import warehouse from "./images/warehouse.png";
// import { Table } from "antd";
import { Table, Flex, Progress } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
// const { Text } = Typography;

const Dashboard = () => {
  const [dataSource, setDataSource] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getApiProducts = async () => {
    try {
      const res = await fetch(
        `https://samhome-iq.com/api/products/store/${Cookies.get("store")}`
      );
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setDataSource(
        data.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const name = nameObj[i18n.language] || nameObj["en"] || "N/A";
          const skus = item.stocks.map((stock) => stock.sku).join(" ");
          return {
            key: item.id,
            id: item.id,
            name: name,
            image_url: item.image_url
              ? `https://samhome-iq.com/api/storage/${item.image_url}`
              : null,
            Sku: skus,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getApiProducts();
  }, [i18n.language]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sku",
      dataIndex: "Sku",
      key: "Sku",
    },
    {
      title: t("image"),
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url) => {
        if (image_url) {
          return (
            <img
              src={image_url}
              alt="Product"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
  ];

  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);

  useEffect(() => {
    getApiProductsimage();
  }, []);

  const getApiProductsimage = async () => {
    try {
      const res = await fetch(
        `https://samhome-iq.com/api/products/store/${Cookies.get("store")}`
      );
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setTotal(data.total);
      setPerPage(data.per_page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const onChange = (pageNumber) => {
  //   console.log("Page: ", pageNumber);
  // };
  const direction = ["ar", "ku", "fa"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku", "fa"].includes(i18n.language)
    ? "right"
    : "left";

  return (
    <div style={{ direction, textAlign }}>
      <div className="dashboard-header">
        <span style={{ fontWeight: "bold" }}>{t("myStocks")}</span>
        <Link to="/Stock">
          <div>
            <img src={pro} alt=" " height={50} width={50} />
            {t("stocks")}
          </div>
        </Link>
      </div>

      <div className="dashboard-container">
        <ul className="list-container">
          {/* <li className="list-item"> */}
          <div className="item-content">
            <span className="icon"></span>
            <Link to="/Allproducts">
              <img
                src={warehouse}
                alt=""
                height={100}
                width={100}
                // className="warehouse-img"
              />
            </Link>
            <span className="number">{t("Allproducts")}</span>
            <Flex gap="small" wrap="wrap">
              <Progress
                percent={((total / perPage) * 100).toFixed(2)}
                status="active"
              />
            </Flex>
            <span className="user">
              {/* {total} {t("Allproducts")} {perPage} */}
              {total} {t("Allproducts")}
            </span>
          </div>
          {/* </li> */}
        </ul>
      </div>

      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        pagination={{
          showQuickJumper: true,
          defaultCurrent: 1,
          total: 1000,
          // onChange,
        }}
        title={() => (
          <div className="dashboard-header">
            <span>{t("recentProducts")}</span>
            <Link to="/Allproducts">
              <div>
                <img src={All} alt=" " height={50} width={50} />
                <span>{t("showAll")}</span>
              </div>
            </Link>
          </div>
        )}
      />
    </div>
  );
};

export default Dashboard;
