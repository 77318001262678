// AllProduct.js
import { List, Button, Input } from "antd";
import { ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AllProduct = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalProducts, setOriginalProducts] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [darkMode, setDarkMode] = useState(false); // State for dark mode

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        ` https://samhome-iq.com/api/products/store/${Cookies.get("store")}`
      );
      const data = await response.json();

      setOriginalProducts(
        data.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const name = nameObj[i18n.language] || nameObj["en"] || "N/A";
          const skus = item.stocks.map((stock) => stock.sku).join(" ");
          const Stocks = item.stocks.map((stock) => stock.stock).join(" ");

          return {
            key: item.id,
            id: item.id,
            name: name,
            image_url: item.image_url
              ? `https://samhome-iq.com/api/storage/${item.image_url}`
              : null,
            Sku: skus,
            Stocks: Stocks,
          };
        })
      );
      setProducts(
        data.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const name = nameObj[i18n.language] || nameObj["en"] || "N/A";
          const firstSku = item.stocks.length > 0 ? item.stocks[0].sku : "N/A";
          const Stocks = item.stocks.map((stock) => stock.stock).join(" ");

          return {
            key: item.id,
            id: item.id,
            name: name,
            // image_url: `https://samhome-iq.com/api/storage/${
            //   stock.files[0]?.path ?? product.image_url
            image_url: item.image_url
              ? `https://samhome-iq.com/api/storage/${item.image_url}`
              : null,
            Sku: firstSku,
            Stocks: Stocks,
            price: item.price,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const onSearch = (value) => {
    setSearchValue(value);
    const filteredProducts = originalProducts.filter(
      (product) =>
        product.name && product.name.toLowerCase().includes(value.toLowerCase())
    );
    setProducts(filteredProducts);
  };

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div>
      <Input.Search
        placeholder={t("search")}
        onSearch={onSearch}
        enterButton
        onChange={(e) => setSearchValue(e.target.value)} // Update the search value when the input changes
        value={searchValue} // Set the input value to the search value state
        style={{ textAlign: "center" }}
      />
      <hr />
      <List
        itemLayout="horizontal"
        dataSource={products}
        loading={loading}
        renderItem={(item) => (
          <List.Item>
            <Link
              to={{
                pathname: `/Allproducts/ProductID/${item.id}`,
              }}
            >
              {item.image_url && (
                <img
                  src={item.image_url}
                  alt="product"
                  height={120}
                  width={120}
                />
              )}
            </Link>

            <span
              className={darkMode ? "detail-label dark-mode" : "detail-label"}
            ></span>
            <div
              className={darkMode ? "product-info dark-mode" : "product-info"}
            >
              Name : {item.name}
              <br />
              Sku : {item.Sku}
              <br />
            </div>
          </List.Item>
        )}
      />

      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop}
      >
        <ArrowUpOutlined />
      </Button>
      <Link to="/Product">
        <Button
          type="primary"
          className="navigate-to-new-page-button"
          onClick={toggleDarkMode}
        >
          <PlusOutlined />
        </Button>
      </Link>
    </div>
  );
};

export default AllProduct;
