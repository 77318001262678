import Home from "./Home";
import Login from "./FrontEnd/Login/Login";
import Cookies from "js-cookie";

const App = () => {
  const isUserAuthenticated = () => {
    const store = Cookies.get("store");
    const user = Cookies.get("store");

    return store !== undefined && user != undefined;
  };

  return isUserAuthenticated() ? <Home /> : <Login />;
};

export default App;
