// Currencies.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles/Currencies.css"; // Import CSS file

const Currencies = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://samhome-iq.com/api/currencies")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      {" "}
      {/* Add the class name */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {data.map((currency) => (
            <div key={currency.id}>
              <h1>{JSON.parse(currency.name)[i18n.language || "en"]}</h1>
              <p>
                {currency.factor} {currency.code} = 1 USD <br />
                {currency.factor * 100} {currency.code} = 100 USD
                <br />
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Currencies;
