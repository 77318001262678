import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  TreeSelect,
  Upload,
} from "antd";
import "./styles/profile.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import down from "../Pages/images/down-arrow.png";
// import { Link } from "react-router-dom";
import { HomeFilled, SaveFilled } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-scroll";
// import { PhoneOutlined } from "@mui/icons-material";

const Profile = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storeData, setStoreData] = useState({});
  const [form] = Form.useForm();
  const [image_url, setImage_url] = useState(null);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = async () => {
    setIsModalOpen2(false);
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.samhome-iq.com/api/stores/${Cookies.get("store")}`
        );

        setStoreData(response.data);

        const imageUrl = response.data.image_url
          ? `${response.data.image_url}`
          : null;

        setImage_url(imageUrl);

        const name = JSON.parse(response.data.name || "{}");
        form.setFieldsValue({
          english: name.en || "",
          arabic: name.ar || "",
          kurdish: name.ku || "",
          turkish: name.tr || "",
          farsi: name.fa || "",
        });
        const address = JSON.parse(response.data.address || "{}");
        form.setFieldsValue({
          englishAddress: address.en || "",
          arabicAddress: address.ar || "",
          kurdishAddress: address.ku || "",
          turkishAddress: address.tr || "",
          farsiAddress: address.fa || "",
        });

        form.setFieldsValue({
          number: response.data.number || "",
          alt_number: response.data.alt_number || "",
          country: JSON.parse(response.data.area.name)[i18n.language || "en"],
        });
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, [form, i18n.language]);
  //todo this is Image ==============================================================
  const handleImageChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "https://api.samhome-iq.com/api/upload-file",
        formData
      );

      if (response.status === 200) {
        const imageUrl = `${response.data.path}`;
        setImage_url(imageUrl);
        // setStoreData((prevStoreData) => ({
        //   ...prevStoreData,
        //   image_url: imageUrl,
        // }));
        // message.success("Image uploaded successfully");
        // console.log("Image uploaded successfully", image_url);
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
    }
  };

  // const normFile = (e) => {
  //   console.log('normFile ',e )
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList ? [e.file] : [];
  // };
  const normFile = (e) => {
    // console.log("Upload event:", e);
    return e && e.fileList ? [e.file] : [];
  };
  const onChangeUpload = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setImage_url(null);
    } else {
      await handleImageChange(file.originFileObj);
    }
  };
  //todo ==================================================================================================
  function findCategoryId(Countries, countryName) {
    for (const country of Countries) {
      const parsedName = JSON.parse(country.name);
      if (
        parsedName.en === countryName ||
        parsedName.ar === countryName ||
        parsedName.ku === countryName ||
        parsedName.tr === countryName ||
        parsedName.fa === countryName
      ) {
        return country.id;
      }

      if (country.cities && country.cities.length > 0) {
        const countryId = findCategoryId(country.cities, countryName);
        if (countryId) {
          return countryId;
        }
      }
    }
  }

  //todo ==================================================================================================
  const onFinish = async (values) => {
    try {
      // console.log("Form values before update:", values);
      values["image_url"] = image_url;

      const name = {
        en: form.getFieldValue("english"),
        ar: form.getFieldValue("arabic"),
        ku: form.getFieldValue("kurdish"),
        tr: form.getFieldValue("turkish"),
        fa: form.getFieldValue("farsi"),
      };
      // console.log("Parsed name:", name);

      const address = {
        en: form.getFieldValue("englishAddress"),
        ar: form.getFieldValue("arabicAddress"),
        ku: form.getFieldValue("kurdishAddress"),
        tr: form.getFieldValue("turkishAddress"),
        fa: form.getFieldValue("farsiAddress"),
      };
      // console.log("Parsed address:", address);

      if (typeof values.country === "string") {
        values.country = findCategoryId(data, values.country);
      }

      const updatedValues = {
        ...values,
        name,
        address,
        area_id: values.country,
      };
      // console.log("Updated values to be sent to the API:", updatedValues);

      const response = await axios.put(
        `https://api.samhome-iq.com/api/stores/${Cookies.get("store")}`,
        updatedValues
      );

      if (response.status === 200) {
        message.success(t("saved"));
        // console.log("Profile updated successfully");
      } else {
        message.error("Failed to update profile");
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile");
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Failed to save profile information");
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://samhome-iq.com/api/areas")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
      });
  }, []);

  const formatTreeData = (Countries) => {
    return Countries.map((country) => ({
      title: JSON.parse(country.name)[i18n.language || "en"],
      value: country.id,
      key: country.id,
      children: country.cities ? formatTreeData(country.cities) : undefined,
      name: country.name,
      disabled: country.parent_id === null,
    }));
  };
  const direction = ["ar", "ku", "fa"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku", "fa"].includes(i18n.language)
    ? "right"
    : "left";
  return (
    <div
      style={{
        direction,
        textAlign,
      }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="login-form-title">{t("profileInformation")}</div>

        <Form.Item
          name="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="image-upload"
        >
          <Upload name="files" onChange={onChangeUpload} maxCount={1}>
            <img
              src={"https://api.samhome-iq.com/storage/" + image_url}
              width={150}
              height={150}
              alt="Store"
            />
          </Upload>
        </Form.Item>

        <Form.Item
          label={<span className="form-label">{t("name")}</span>}
          name="storeName"
          className="store-name"
        >
          <Button
            type="text"
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              display: "inline-block",
              width: "70%",
              textAlign: "right",
            }}
            className="button"
            onClick={showModal}
          >
            <img src={down} alt="image" width={20} height={20} />
          </Button>
        </Form.Item>

        <Modal
          style={{
            direction,
            textAlign,
          }}
          title="Store Information"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form.Item name="english" label={t("english")}>
            <Input
              placeholder={t("english")}
              value={storeData.name ? JSON.parse(storeData.name).en : ""}
            />
          </Form.Item>
          <Form.Item name="arabic" label={t("arabic")}>
            <Input
              placeholder={t("arabic")}
              value={storeData.name ? JSON.parse(storeData.name).ar : ""}
            />
          </Form.Item>
          <Form.Item name="kurdish" label={t("kurdish")}>
            <Input
              placeholder={t("kurdish")}
              value={storeData.name ? JSON.parse(storeData.name).ku : ""}
            />
          </Form.Item>
          <Form.Item name="turkish" label={t("turkish")}>
            <Input
              placeholder={t("turkish")}
              value={storeData.name ? JSON.parse(storeData.name).tr : ""}
            />
          </Form.Item>
          <Form.Item name="farsi" label={t("farsi")}>
            <Input
              placeholder={t("farsi")}
              value={storeData.name ? JSON.parse(storeData.name).fa : ""}
            />
          </Form.Item>
        </Modal>

        <Form.Item
          label={<span className="form-label">{t("phoneNumber")}</span>}
          name="number"
          className="phone-number"
        >
          <InputNumber
            style={{ width: "70%", paddingLeft: 20 }}
            // suffix={
            //   <PhoneOutlined style={{ position: "absolute", right: 10 }} />
            // }
          />
        </Form.Item>
        {/* <Form.Item label={t("alternatePhoneNumber")} name="alt_number"> */}
        <Form.Item
          label={
            <span className="form-label">{t("alternatePhoneNumber")}</span>
          }
          name="alt_number"
        >
          <InputNumber
            style={{ width: "70%", paddingLeft: 24 }}
            // suffix={
            //   <PhoneOutlined style={{ position: "absolute", right: 10 }} />
            // }
          />
        </Form.Item>

        <Form.Item
          label={<span className="form-label">{t("changeAddress")}</span>}
          name="storeName"
        >
          <Button
            type="text"
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              display: "inline-block",
              width: "70%",
              textAlign: "right",
            }}
            className="button"
            onClick={showModal2}
          >
            <img src={down} alt="image" width={20} height={20} />
          </Button>
        </Form.Item>
        <Modal
          style={{
            direction,
            textAlign,
          }}
          title="Basic Modal"
          open={isModalOpen2}
          onOk={handleOk2}
          onCancel={handleCancel2}
        >
          <Form.Item name="englishAddress" label={t("english")}>
            <Input
              placeholder={t("english")}
              value={storeData.address ? JSON.parse(storeData.address).en : ""}
            />
          </Form.Item>
          <Form.Item name="arabicAddress" label={t("arabic")}>
            <Input
              placeholder={t("arabic")}
              value={storeData.address ? JSON.parse(storeData.address).ar : ""}
            />
          </Form.Item>
          <Form.Item name="kurdishAddress" label={t("kurdish")}>
            <Input
              placeholder={t("kurdish")}
              value={storeData.address ? JSON.parse(storeData.address).ku : ""}
            />
          </Form.Item>
          <Form.Item name="turkishAddress" label={t("turkish")}>
            <Input
              placeholder={t("turkish")}
              value={storeData.address ? JSON.parse(storeData.address).tr : ""}
            />
          </Form.Item>
          <Form.Item name="farsiAddress" label={t("farsi")}>
            <Input
              placeholder={t("farsi")}
              value={storeData.address ? JSON.parse(storeData.address).fa : ""}
            />
          </Form.Item>
        </Modal>

        <Form.Item
          label={<span className="form-label">{t("selectArea")}</span>}
          name="country"
        >
          <TreeSelect
            showSearch
            style={{ width: "70%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={formatTreeData(data)}
            placeholder="Please select"
            treeDefaultExpandAll
          />
        </Form.Item>

        <br />
        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 12,
            xs: { span: 24 },
            sm: { span: 12, offset: 6 },
          }}
          style={{ textAlign: "center" }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginRight: "10px",
              backgroundColor: "#f44336",
              borderColor: "#f44336",
            }}
          >
            {t("save")}
            <SaveFilled />
          </Button>
          <Button
            type="primary"
            href="/"
            style={{ backgroundColor: "#2196f3", borderColor: "#2196f3" }}
          >
            {t("dashboard")}
            <HomeFilled />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profile;
