import "./App.css";
import Navbar from "./FrontEnd/Navbar/Navbar";
// import { Routes, Route, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./FrontEnd/Pages/Dashboard";
import Cookies from "js-cookie";
import Profile from "./FrontEnd/Pages/Profile";
import Stock from "./FrontEnd/Pages/Stock";
import Currencies from "./FrontEnd/Pages/Currencies";
import LogOut from "./FrontEnd/Login/LogOut";
// import Textt from "./FrontEnd/Pages/Textt";
import NotFound from "./FrontEnd/Pages/NotFound";
// import TestApi from "./FrontEnd/Pages/TestApi";
import UpdateAllProduct from "./FrontEnd/Pages/UpdateAllProduct";
import AllProduct from "./FrontEnd/Pages/AllProduct";
import Product from "./FrontEnd/Pages/Product";
import { useEffect, useState } from "react";
import ProductID from "./FrontEnd/Pages/productID";
import i18n from "./i18n";
// import { useTranslation } from "react-i18next";
import axios from "axios";

function Home() {
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);
  // const [form] = Form.useForm();
  // const { i18n } = useTranslation();
  // const [products, setProducts] = useState([]);
  const [storeData, setStoreData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ` https://samhome-iq.com/api/products/store/${Cookies.get("store")}`
        );
        setStoreData(response.data.data);
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, []);
  // const location = useLocation();
  // const isNavbarHidden =
  // location.pathname === "/" ;

  return (
    // <div className="App-header">
    <div>
      {/* {!isNavbarHidden && <Navbar />} */}
      <Navbar />
      <Routes>
        <Route path="/LogOut" element={<LogOut />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Product" element={<Product />} />
        <Route path="/Allproducts" element={<AllProduct />} />
        <Route
          path="/Allproducts/ProductID/:id"
          element={<ProductID storeData={storeData} />}
        />
        {/* <Route
          path="/AllProduct/ProductDetail/:id"
          element={<ProductDetail products={products} />}
        /> */}
        <Route path="/UpdateAllProduct" element={<UpdateAllProduct />} />
        <Route path="/Stock" element={<Stock />} />
        <Route path="/Currencies" element={<Currencies />} />
        {/* <Route path="/Textt" element={<Textt />} /> */}
        {/* <Route path="/TestApi" element={<TestApi />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Home;
