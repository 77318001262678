import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  TreeSelect,
  Upload,
  message,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import videoIcon from "./images/video.png";
import imageIcon from "./images/image.png";
import imagesIcon from "./images/gallery.png";
import "./styles/NumStocks.css";
// import axios from "axios";
import {
  DownOutlined,
  HomeFilled,
  SaveFilled,
  BgColorsOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const Product = () => {
  const { TextArea } = Input;
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  // const onFinish = (values) => {
  //   console.log("Form Submit", values);
  //   message.success("Form Submit");
  // };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("error");
  };
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameKurdish, setNameKurdish] = useState("");
  const [nameArabic, setNameArabic] = useState("");
  const [nameTurkish, setNameTurkish] = useState("");
  const [nameFarsi, setNameFarsi] = useState("");
  const [descriptionEnglish, setDescriptionEnglish] = useState("");
  const [descriptionKurdish, setDescriptionKurdish] = useState("");
  const [descriptionArabic, setDescriptionArabic] = useState("");
  const [descriptionTurkish, setDescriptionTurkish] = useState("");
  const [descriptionFarsi, setDescriptionFarsi] = useState("");
  const [price, setprice] = useState(0);
  const [discounted_price, setdiscounted_price] = useState(0);
  const [active, setactive] = useState(false);
  const [category_id, setcategory_id] = useState(0);
  const [currency_id, setcurrency_id] = useState(0);
  const [image_url, setImage_url] = useState(null);
  const [video_url, setVideo_url] = useState(null);
  const [stocks, setStocks] = useState([
    { sku: "", stock: "1", color: null, hex: null, files: [] },
  ]);

  const handleStockChange = (index, field, value) => {
    setStocks((prevStocks) => {
      const newStocks = [...prevStocks];
      newStocks[index] = {
        ...newStocks[index], // Preserve other fields of the stock object
        [field]: value, // Update the specified field with the new value
      };
      return newStocks;
    });
  };

  const handleFormSubmit = async () => {
    try {
      const formData = new FormData();

      await Promise.all(
        stocks.map(async (stock, stockIndex) => {
          const fileUploadPromises = stock.files.map(
            async (image, fileIndex) => {
              try {
                const fileData = new FormData();
                fileData.append("file", image.originFileObj);
                const response = await axios.post(
                  "https://api.samhome-iq.com/api/upload-file",
                  fileData
                );
                return response.data; // Return the file path
              } catch (error) {
                console.error("Error uploading file:", error);
                throw error; // Propagate error to stop the submission
              }
            }
          );

          const uploadedFiles = await Promise.all(fileUploadPromises);
          stocks[stockIndex].images = uploadedFiles; // Update the files array with uploaded file paths
        })
      );

      formData.append(
        "name",
        JSON.stringify({
          en: nameEnglish,
          ku: nameKurdish,
          ar: nameArabic,
          tr: nameTurkish,
          fa: nameFarsi,
        })
      );
      formData.append(
        "description",
        JSON.stringify({
          en: descriptionEnglish,
          ku: descriptionKurdish,
          ar: descriptionArabic,
          tr: descriptionTurkish,
          fa: descriptionFarsi,
        })
      );
      formData.append("currency_id", currency_id);
      // formData.append("store_id", props['store_id']);

      formData.append("store_id", Cookies.get("store"));
      formData.append("category_id", category_id);
      formData.append("price", price);
      formData.append("discounted_price", discounted_price);
      formData.append("Active", active);
      formData.append("image_url", image_url);
      formData.append("video_url", video_url);

      console.log(stocks);
      const updatedStocks = stocks.map(({ files, ...rest }) => ({
        sku: rest.sku,
        stock: rest.stock,
        color: rest.color,
        hex: rest.hex,
        files: JSON.stringify(rest.images),
      }));

      console.log("updated stocks  ", updatedStocks);

      let stocksArray = JSON.stringify(updatedStocks);
      formData.append("stocks", stocksArray);
      console.log("stocksArray", stocksArray);
      console.log(formData);
      for (const pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      console.log("here ");
      const response = await fetch("https://samhome-iq.com/api/products", {
        method: "POST",
        body: formData,
      });
      // console.log('here ' )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // console.log("here ", response.status);
      // console.log("111111111111111");
      const responseData = await response.json();
      console.log("Response:", responseData);
      // console.log("2222222222222222");
      message.success(t("AddedSuccessfully"));
      window.location.href = "/Allproducts";
    } catch (error) {
      console.error("Error adding product:", error);
      message.error("Failed to add product");
    }
  };
  //todo ==================this is colors ================================================
  const [isModalVisibleColors, setIsModalVisibleColors] = useState(false);
  // const [showAdditionalInput, setShowAdditionalInput] = useState([]);
  const [showAdditionalInput] = useState([]);
  const showModalColors = () => {
    setIsModalVisibleColors(true);
  };
  const handleOkColors = () => {
    setIsModalVisibleColors(false);
  };
  const handleCancelColors = () => {
    setIsModalVisibleColors(false);
  };
  const [selectedColors, setSelectedColors] = useState([]);
  // const [colors, setColors] = useState([
  const [colors] = useState([
    
    { hex: "0xFF000000", color: "Black", isSelected: false },
    { hex: "0xFFFFFFFF", color: "White", isSelected: false },
    { hex: "0xFF6B4423", color: "Brown Wooden", isSelected: false },
    { hex: "0xFF8B4513", color: "Saddle Brown", isSelected: false },
    { hex: "0xFFA0522D", color: "Sienna", isSelected: false },
    { hex: "0xFF808080", color: "Slate Grey", isSelected: false },
    { hex: "0xFF8B7D6B", color: "Khaki", isSelected: false },
    { hex: "0xFFCD853F", color: "Peru", isSelected: false },
    { hex: "0xFF2E8B57", color: "Sea Green", isSelected: false },
    { hex: "0xFF556B2F", color: "Dark Olive Green", isSelected: false },
    { hex: "0xFFBDB76B", color: "Dark Khaki", isSelected: false },
    { hex: "0xFF808000", color: "Olive", isSelected: false },
    { hex: "0xFFD2691E", color: "Chocolate", isSelected: false },
    { hex: "0xFF696969", color: "Dim Grey", isSelected: false },
    { hex: "0xFFDCDCDC", color: "Light Grey", isSelected: false },
    { hex: "0xFFDCDCDC", color: "Gainsboro", isSelected: false },
    { hex: "0xFFD2B48C", color: "Tan", isSelected: false },
    { hex: "0xFF4B0082", color: "Indigo", isSelected: false },
    { hex: "0xFF483D8B", color: "Dark Slate Blue", isSelected: false },
    { hex: "0xFF2F4F4F", color: "Dark Slate Grey", isSelected: false },
    { hex: "0xFF000080", color: "Navy", isSelected: false },
    { hex: "0xFF800000", color: "Maroon", isSelected: false },
    { hex: "0xFF008080", color: "Teal", isSelected: false },
    { hex: "0xFF006400", color: "Dark Green", isSelected: false },
    { hex: "0xFF8B0000", color: "Dark Red", isSelected: false },
    { hex: "0xFFCD5C5C", color: "Indian Red", isSelected: false },
    { hex: "0xFFBC8F8F", color: "Rosy Brown", isSelected: false },
    { hex: "0xFFFA8072", color: "Salmon", isSelected: false },
    { hex: "0xFF32CD32", color: "Lime Green", isSelected: false },
    { hex: "0xFF7FFFD4", color: "Aquamarine", isSelected: false },
    { hex: "0xFF4682B4", color: "Steel Blue", isSelected: false },
    { hex: "0xFF20B2AA", color: "Light Sea Green", isSelected: false },
    { hex: "0xFF00FA9A", color: "Medium Spring Green", isSelected: false },
    { hex: "0xFF8A2BE2", color: "Blue Violet", isSelected: false },
    { hex: "0xFFD8BFD8", color: "Thistle", isSelected: false },
    { hex: "0xFFB0C4DE", color: "Light Steel Blue", isSelected: false },
    { hex: "0xFF8FBC8B", color: "Dark Sea Green", isSelected: false },
    { hex: "0xFFB0E0E6", color: "Powder Blue", isSelected: false },
    { hex: "0xFFC71585", color: "Medium Violet Red", isSelected: false },
    { hex: "0xFF556B2F", color: "Olive Drab", isSelected: false },
    { hex: "0xFFA52A2A", color: "Brown", isSelected: false },
    { hex: "0xFF8B008B", color: "Dark Magenta", isSelected: false },
    { hex: "0xFFB8860B", color: "Dark Goldenrod", isSelected: false },
    { hex: "0xFFDAA520", color: "Goldenrod", isSelected: false },
    { hex: "0xFF9400D3", color: "Dark Violet", isSelected: false },
    { hex: "0xFFD2691E", color: "Dark Orange", isSelected: false },
    { hex: "0xFFB22222", color: "Fire Brick", isSelected: false },
    { hex: "0xFFFF0000", color: "Red", isSelected: false },
    { hex: "0xFF00FF00", color: "Green", isSelected: false },
    { hex: "0xFF0000FF", color: "Blue", isSelected: false },
    { hex: "0xFFFFFF00", color: "Yellow", isSelected: false },
    { hex: "0xFFFFA500", color: "Orange", isSelected: false },
    { hex: "0xFF800080", color: "Purple", isSelected: false },
    { hex: "0xFFFFC0CB", color: "Pink", isSelected: false },
  ]);
  const handleChangeColors = (indexColor) => {
    console.log(indexColor);
    const updatedColors = [...colors]; // Create a copy of the colors array
    updatedColors[indexColor].isSelected =
      !updatedColors[indexColor].isSelected;
    const newSelectedColors = updatedColors.filter((e) => e.isSelected);
    setSelectedColors(newSelectedColors);
    setStocks((prevStocks) =>
      newSelectedColors.length === 0
        ? [{ sku: "", stock: "1", color: null, hex: null, files: [] }]
        : newSelectedColors.map((color) => ({
            color: color.color,
            hex: color.hex,
            sku: "",
            stock: "1",
            files: [],
          }))
    );
  };
  

  useEffect(() => {
    console.log("Updated stocks:", stocks);
  }, [stocks]);
  //todo
  const hexToRgb = (hex) => {
    // Function to convert hex to rgb
    let bigint = parseInt(hex.replace("0x", ""), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };
  //todo add multeple  image
  const [files, setFiles] = useState([]);
  const fileGallery = (e, index) => {
    console.log(index);
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }

    console.log(index);
    console.log(stocks[index]);
    stocks[index].files = files;
    console.log(files);
    return e?.fileList;
  };
  //!========================================================
  //todo -----------Api for currencies----------------
  const [dataCurrencies, setDataCurrencies] = useState([]);
  useEffect(() => {
    fetch("https://samhome-iq.com/api/currencies")
      .then((response) => response.json())
      .then((data) => {
        setDataCurrencies(data);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }, []);
  //todo -----------Api for categories----------------
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("https://samhome-iq.com/api/categories")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        // message.error("Failed to fetch data");
      });
  }, []);

  const formatTreeData = (categories) => {
    return categories.map((category) => {
      const hasChildren =
        category.subcategories && category.subcategories.length > 0;
      return {
        title: (
          <span
            style={{ display: "flex", alignItems: "center", color: "black" }}
          >
            {category.image_url && (
              <img
                src={`https://samhome-iq.com/api/storage/${category.image_url}`}
                alt={category.name}
                style={{ width: 50, marginRight: 8 }}
              />
            )}
            {JSON.parse(category.name)[i18n.language || "en"]}
          </span>
        ),
        value: category.id,
        key: category.id,
        children: hasChildren
          ? formatTreeData(category.subcategories)
          : undefined,
        disabled: hasChildren, // Disable the node if it has children
      };
    });
  };

  //todo this is Details ------------------------------------------------
  //todo this is Modal  Name
  const [isModalOpenNew, setIsModalOpenNew] = useState(false);
  const [form] = Form.useForm(); // Form instance for the outer form
  const handleCancelNew = () => {
    setIsModalOpenNew(false);
  };
  const showModalNew = () => {
    setIsModalOpenNew(true);
  };

  const handleOkNew = () => {
    setIsModalOpenNew(false);
  };
  //! End modal
  //todo this is Modal discounted price
  const [isModalOpenNew2, setIsModalOpenNew2] = useState(false);
  const handleCancelNew2 = () => {
    setIsModalOpenNew2(false);
  };
  const showModalNew2 = () => {
    setIsModalOpenNew2(true);
  };

  const handleOkNew2 = () => {
    setIsModalOpenNew2(false);
  };

  //todo this is Image========================================
  const handleImageChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      // console.log("trying to upload image");

      const response = await axios.post(
        "https://api.samhome-iq.com/api/upload-file",
        formData
      );
      // console.log("response from image");
      // console.log(response.data);

      if (response.status === 200) {
        const imageUrl = `${response.data.path}`;
        // console.log("imagessssssss", imageUrl);
        setImage_url(imageUrl);

        // message.success("Image uploaded successfully");
        // console.log("Image uploaded successfully", image_url);
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
    }
  };
  // const fileImage = (e) => (e && e.fileList ? [e.file] : []);
  const FileImage = (e) => {
    // console.log("Upload event:", e);
    return e && e.fileList ? [e.file] : [];
  };
  const onchangeImage = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setImage_url(null);
    } else {
      await handleImageChange(file.originFileObj);
    }
  };
  // const onchangeImage = async ({ file, fileList }) => {
  //   if (file.status === "done") {
  //     await handleImageChange(file.originFileObj);
  //   } else if (file.status === "removed") {
  //     setImageUrl(null);
  //   }
  // };
  //! this is End  Image========================================
  //todo this is Video ========================================================
  const handleVideoChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      // console.log("log");
      const response = await axios.post(
        "https://api.samhome-iq.com/api/upload-file",
        formData
      );
      // console.log("log2");

      if (response.status === 200) {
        const videoUrl = `${response.data.path}`;

        // console.log("Videooooooooooooooo", videoUrl);
        setVideo_url(videoUrl);

        // message.success("video uploaded successfully");
        // console.log("Image uploaded successfully", video_url);
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image");
    }
  };
  // const acceptVideoTypes = ["video/mp4", "video/x-msvideo", "video/quicktime"];

  const FileVideo = (e) => {
    // if (Array.isArray(e)) {
    //   return e;
    // }
    // return e && e.fileList ? [e.file] : [];
    return e && e.fileList ? [e.file] : [];
  };
  const beforeUploadVideo = async (file, fileList) => {
    // console.log(file);
    await handleVideoChange(file);
  };

  const direction = ["ar", "ku", "fa"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku", "fa"].includes(i18n.language)
    ? "right"
    : "left";
  return (
    <Form
      // onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 800,
        margin: "0 auto" /* Add this line to center the form */,
        direction,
        textAlign,
      }}
      initialValues={{
        isSelected: false,
      }}
      autoComplete="off"
    >
      {/* //todo -----  start Info Form ----------------------------- */}
      <div style={{ marginBottom: 20, fontSize: 20, textAlign: "center" }}>
        <div className="login-form-title">{t("AddProduct")}</div>
        {/* {t("AddProduct")} */}
      </div>
      {/* //todo this is image  */}
      <Form.Item
        name="image"
        label={<span className="form-label">{t("image")}</span>}
        valuePropName="fileList"
        getValueFromEvent={FileImage}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        rules={[{ required: true, message: "Please input your image!" }]}
      >
        <Upload
          name="file"
          // onChange={(e) => e.file.path}
          onChange={onchangeImage}
          maxCount={1}
          // action="/upload.do"
          // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          // fileList={
          //   image_url
          //     ? [
          //         {
          //           uid: "-1",
          //           name: "image.png",
          //           status: "done",
          //           url: image_url,
          //         },
          //       ]
          //     : []
          // }
          // listType="picture"
        >
          <img
            src={imageIcon}
            width={120}
            height={120}
            style={{
              borderRadius: "30px",
            }}
            alt="Store"
          />
        </Upload>
      </Form.Item>
      {/* //! this is end  image  */}
      {/* //Todo this is Video  */}
      <Form.Item
        name="video"
        label={<span className="form-label">{t("video")}</span>}
        valuePropName="fileList"
        getValueFromEvent={FileVideo}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Upload
          name="files"
          // action="/upload.do"
          beforeUpload={beforeUploadVideo}
          maxCount={1}
        >
          <img
            src={videoIcon}
            width={120}
            height={120}
            style={{
              borderRadius: "30px",
            }}
            alt="Store"
          />
        </Upload>
      </Form.Item>
      {/* //! this is video  */}
      {/* //Todo this is Modal  name*/}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 80,
          borderRadius: "4px", // Add border radius
        }}
      >
        <Form.Item name="name">
          <Button
            type="text"
            style={{
              width: "200px", // Set the width to 200px
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              display: "inline-block",

              textAlign: "center", // Center align text
              backgroundColor: "#f0f0f0", // Change background color
              color: "#333", // Change text color
              fontWeight: "bold", // Make text bold
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", // Add a box shadow
            }}
            onClick={showModalNew}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {t("name")}
              <DownOutlined />
            </div>
          </Button>

          <Modal
            title="Basic Modal"
            open={isModalOpenNew}
            onOk={handleOkNew}
            onCancel={handleCancelNew}
          >
            <Form
              form={form}
              // onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item name="english" label={t("english")}>
                <Input
                  placeholder={t("english")}
                  value={nameEnglish}
                  onChange={(e) => setNameEnglish(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="arabic"
                label={t("arabic")}
                value={nameArabic}
                onChange={(e) => setNameArabic(e.target.value)}
              >
                <Input placeholder={t("arabic")} />
              </Form.Item>
              <Form.Item
                name="kurdish"
                label={t("kurdish")}
                value={nameKurdish}
                onChange={(e) => setNameKurdish(e.target.value)}
              >
                <Input placeholder={t("kurdish")} />
              </Form.Item>
              <Form.Item
                name="turkish"
                label={t("turkish")}
                value={nameTurkish}
                onChange={(e) => setNameTurkish(e.target.value)}
              >
                <Input placeholder={t("turkish")} />
              </Form.Item>
              <Form.Item
                name="farsi"
                label={t("farsi")}
                value={nameFarsi}
                onChange={(e) => setNameFarsi(e.target.value)}
              >
                <Input placeholder={t("farsi")} />
              </Form.Item>
            </Form>
          </Modal>
        </Form.Item>
        {/* //!  End Modal */}
        {/* //TODO modal Store Address ----------------------------------------  */}
        <Form.Item name="description">
          <Button
            type="text"
            style={{
              width: "200px", // Set the width to 200px
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              display: "inline-block",

              textAlign: "center", // Center align text
              backgroundColor: "#f0f0f0", // Change background color
              color: "#333", // Change text color
              fontWeight: "bold", // Make text bold
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", // Add a box shadow
            }}
            onClick={showModalNew2}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {t("desc")}
              <DownOutlined />
            </div>
          </Button>

          <Modal
            title="Basic Modal"
            open={isModalOpenNew2}
            onOk={handleOkNew2}
            onCancel={handleCancelNew2}
          >
            <Form
              form={form}
              // onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item name="Aenglish" label={t("english")}>
                <TextArea
                  showCount
                  maxLength={250}
                  // onChange={onChange}
                  style={{
                    height: 65,
                    resize: "none",
                  }}
                  placeholder={t("english")}
                  value={descriptionEnglish}
                  onChange={(e) => setDescriptionEnglish(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Aarabic" label={t("arabic")}>
                <TextArea
                  showCount
                  maxLength={250}
                  // onChange={onChange}
                  style={{
                    height: 65,
                    resize: "none",
                  }}
                  placeholder={t("arabic")}
                  value={descriptionArabic}
                  onChange={(e) => setDescriptionArabic(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Akurdish" label={t("kurdish")}>
                <TextArea
                  showCount
                  maxLength={250}
                  // onChange={onChange}
                  style={{
                    height: 65,
                    resize: "none",
                  }}
                  placeholder={t("kurdish")}
                  value={descriptionKurdish}
                  onChange={(e) => setDescriptionKurdish(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Aturkish" label={t("turkish")}>
                <TextArea
                  showCount
                  maxLength={250}
                  // onChange={onChange}
                  style={{
                    height: 65,
                    resize: "none",
                  }}
                  placeholder={t("turkish")}
                  value={descriptionTurkish}
                  onChange={(e) => setDescriptionTurkish(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Afarsi" label={t("farsi")}>
                <TextArea
                  showCount
                  maxLength={250}
                  // onChange={onChange}
                  style={{
                    height: 65,
                    resize: "none",
                  }}
                  placeholder={t("farsi")}
                  value={descriptionFarsi}
                  onChange={(e) => setDescriptionFarsi(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Modal>
        </Form.Item>
      </div>
      {/* //!  End Modal */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 60,
          borderRadius: "4px", // Add border radius
        }}
      >
        <Form.Item
          name="price"
          label={<span className="form-label">{t("price")}</span>}
          // rules={[{ required: true, message: "Please input your price!" }]}
        >
          <Input
            value={price}
            onChange={(e) => setprice(e.target.value)}
            placeholder="0.0"
            style={{ width: "100%", paddingLeft: 20 }}
          />
        </Form.Item>
        <Form.Item
          label={<span className="form-label">{t("discountedPrice")}</span>}
          name="discounted_price"
          style={{
            marginLeft: "20px", // Add margin top
            transition: "margin-top 0.3s", // Add transition for smooth effect
          }}
          // rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input
            value={discounted_price}
            onChange={(e) => setdiscounted_price(e.target.value)}
            style={{ width: "100%", paddingLeft: 20 }}
            placeholder="0.0"
          />
        </Form.Item>
      </div>
      {/* // todo this is Api for Currency */}
      <Form.Item
        label={<span className="form-label">{t("currency")}</span>}
        name="currency"
        rules={[{ required: true, message: "Please input your currency!" }]}
      >
        <Select
          placeholder="Select currency"
          style={{ width: "84%" }}
          value={currency_id}
          onChange={(value) => setcurrency_id(value)}
        >
          {dataCurrencies.map((currency) => (
            <Select.Option key={currency.id} value={currency.id}>
              {JSON.parse(currency.symbol)[i18n.language || "en"]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* // todo this is Api for Category */}
      <Form.Item
        label={<span className="form-label">{t("category")}</span>}
        name="category"
        rules={[{ required: true, message: "Please input your category!" }]}
      >
        <TreeSelect
          value={category_id}
          onChange={(value) => setcategory_id(value)}
          showSearch
          style={{ width: "84%" }}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={formatTreeData(data)}
          placeholder="Please select"
          treeDefaultExpandAll
        />
      </Form.Item>
      {/* //TODO modal ----------------------------------------  */}
      <Form.Item
        name="active"
        label={<span className="form-label">{t("active")}</span>}
        valuePropName="checked"
        // rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Switch checked={active} onChange={setactive} />
      </Form.Item>
      {/* //! end Switch */}
      {/* //todo this is color =========================================================================== */}
      {/* //todo this is color =========================================================================== */}
      <Form.Item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            border: "none",
            padding: "15px 30px",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "5px",
            transition: "background-color 0.3s",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={showModalColors}
        >
          {t("colors")}
          <BgColorsOutlined />
        </Button>
      </Form.Item>

      {/* //todo this is modal========================== */}
      <Modal
        title="Remember Me Details"
        open={isModalVisibleColors}
        onOk={handleOkColors}
        onCancel={handleCancelColors}
      >
        {colors.map((color, indexColor) => (
          <div
            key={indexColor}
            style={{
              backgroundColor: color.hex,
              padding: "10px",
              marginBottom: "5px",
            }}
          >
            <Form.Item
              name={color.hex}
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox
                defaultChecked={color.isSelected}
                // defaultChecked={showAdditionalInput[indexColor]}
                onChange={() => handleChangeColors(indexColor)}
              >
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {color.color}
                  <span
                    style={{
                      display: "inline-block",
                      width: 25,
                      height: 25,
                      backgroundColor: hexToRgb(color.hex),
                      marginLeft: 5,
                      borderRadius: "50%",
                      border: "1px solid black",
                      boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)", // Optional: Add a shadow
                    }}
                  ></span>
                </span>
              </Checkbox>
            </Form.Item>
          </div>
        ))}
      </Modal>
      {/* //todo this is stocks========================== */}
      {stocks.map((stock, index) => (
        <div key={index}>
          <div>
            {stock.hex != null && (
              <ul className="color-list">
                <li className="login-form-title">{t("color")} :</li>
                {/* <li>{t("color")} :</li> */}
                <li
                  style={{
                    backgroundColor: hexToRgb(stock.hex),
                    padding: "5px",
                    borderRadius: "3px",
                    margin: "2px",
                  }}
                >
                  {stock.color}
                </li>
              </ul>
            )}

            {/* {stock.hex != null ? (
              <ul className="color-list">
                <li className="login-form-title">{t("color")} :</li>
                <li
                  style={{
                    backgroundColor: hexToRgb(stock.hex),
                    padding: "5px",
                    borderRadius: "3px",
                    margin: "2px",
                  }}
                >
                  {stock.color}
                </li>
              </ul>
            ) : (
              <p>Show some text here when stock.hex is null or undefined</p>
            )} */}
            <Form.Item
              label={<span className="form-label">SKU</span>}
              // name={`AdditionalInput${index}`}
              style={{ marginBottom: "8px" }}
            >
              <Input
                placeholder="SKU"
                value={stock.sku}
                style={{ width: "84%" }}
                onChange={(e) =>
                  handleStockChange(index, "sku", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label={<span className="form-label">{t("stock")}</span>}
              // name={`Stock${index}`}
              style={{ marginBottom: 8 }}
            >
              <InputNumber
                placeholder={t("stock")}
                style={{ width: "84%", paddingLeft: 20 }}
                value={stock.stock}
                onChange={(value) => handleStockChange(index, "stock", value)}
              />
            </Form.Item>

            <div
              style={{
                marginBottom: 20,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              <div className="login-form-title">{t("inputmultiple")}</div>
              {/* {t("inputmultiple")} */}
            </div>
            <div></div>
            <Form.Item
              name={`Upload${index}`}
              // label="Upload1"
              valuePropName="fileList"
              getValueFromEvent={(e) => fileGallery(e, index)}
              // getValueFromEvent={(e) => fileGallery(e, index - 1)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Upload
                // listType="picture"
                // name="logo"
                fileList={files}
                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                // listType="picture"
                multiple
                onChange={({ fileList }) => setFiles(fileList)}
              >
                <img src={imagesIcon} alt="images" width={120} height={120} />
              </Upload>
            </Form.Item>
            <hr
              style={{
                borderBottom: "2px solid black",
              }}
            />
          </div>
        </div>
      ))}
      {/* //! this is ENd color  */}
      <Form.Item
        wrapperCol={{
          offset: 6,
          span: 12,
          xs: { span: 24 },
          sm: { span: 12, offset: 6 },
        }}
        style={{ textAlign: "center" }}
      >
        <Button
          onClick={handleFormSubmit}
          type="primary"
          htmlType="submit"
         
          style={{
            marginRight: "10px",
            backgroundColor: "#f44336",
            borderColor: "#f44336",
          }}
        >
          {t("save")}
          <SaveFilled />
        </Button>
        <Button
          type="primary"
          href="/"
          style={{ backgroundColor: "#2196f3", borderColor: "#2196f3" }}
        >
          {t("dashboard")}
          <HomeFilled />
        </Button>
      </Form.Item>
      {/* //! this is ENd color  */}
    </Form>
  );
};
export default Product;
