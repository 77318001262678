import "./styles/Lang.css";
/* eslint-disable jsx-a11y/img-redundant-alt */
// import lang from "./images/world.png";
import iraq from "./images/iraq.png";
import kurdish from "./images/flag.png";
import english from "./images/united-states-of-america.png";
import turkce from "./images/turkey.png";
import persia from "./images/Persia.png";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
const Lang = () => {
  //Todo--------------
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();
  const onChangeLang = (e) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    Cookies.set("language", selectedLang); // Save selected language to a cookie
    localStorage.removeItem("i18nextLng");
    // window.location.reload();
  };

  useEffect(() => {
    const savedLang = Cookies.get("language");
    if (savedLang) {
      setLanguage(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, []);
  //Todo--------------
  return (
    <div>
      <FormControl
        style={{
          width: 200,
        }}
      >
        <InputLabel id="demo-simple-select-label">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <img src={lang} alt="image" width={30} height={30} /> */}
            <span className="label-text">{t("language")}</span>
          </div>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language || ""}
          onChange={onChangeLang}
          label="Age"
        >
          <MenuItem value="en">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={english} alt="image" width={30} height={30} />
              <span style={{ marginLeft: "10px" }}> </span>{" "}
              <span className="label-text">English</span>
            </div>
          </MenuItem>
          <MenuItem value="ar">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iraq} alt="image" width={30} height={30} />
              <span style={{ marginLeft: "10px" }}> </span>
              <span className="label-text">العربية</span>
            </div>
          </MenuItem>
          <MenuItem value="ku">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={kurdish} alt="image" width={30} height={30} />
              <span style={{ marginLeft: "10px" }}> </span>
              <span className="label-text">کوردی</span>
            </div>
          </MenuItem>
          <MenuItem value="tr">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={turkce} alt="image" width={30} height={30} />
              <span style={{ marginLeft: "10px" }}> </span>
              <span className="label-text">Turkce</span>
            </div>
          </MenuItem>
          <MenuItem value="fa">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={persia} alt="image" width={30} height={30} />
              <span style={{ marginLeft: "10px" }}> </span>
              <span className="label-text">فارسی</span>
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Lang;
