import React from "react";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";

const LogOut = () => {
  const handleLogout = () => {
    // Log the cookies before attempting to remove them
    // console.log("Cookies before removal:", Cookies.get());

    // Remove cookies
    Cookies.remove("user");
    Cookies.remove("store");

    // Log the cookies after attempting to remove them
    // console.log("Cookies after removal:", Cookies.get());

    // window.location.href = "/";
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Button
        onClick={handleLogout}
        style={{
          backgroundColor: "#f5222d",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          padding: "10px 20px",
          fontSize: "16px",
          fontWeight: "bold",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          transition: "background-color 0.3s",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={<LogoutOutlined />}
      >
        Logout
      </Button>
    </div>
  );
};

export default LogOut;
