import React, { useEffect, useState } from "react";
import { List, Input, Modal, Form, InputNumber, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./styles/AllProducts.css";
import axios from "axios";
import Cookies from "js-cookie";
const Stock = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const fetchProducts = (sku) => {
    setLoading(true);
    let url = `https://api.samhome-iq.com/api/products/${sku}/store/${Cookies.get(
      "store"
    )}`;
    // let url;
    // if (sku === "") {
    //   url = `https://api.samhome-iq.com/api/products/store/12`;
    // } else {
    //   url = `https://api.samhome-iq.com/api/products/${sku}/store/12`;
    // }

    axios
      .get(url)
      .then((response) => {
        const product = response.data[0];
        const stock = product.stocks.find((stock) => stock.sku === sku);
        const nameObj = JSON.parse(product.name);
        const name = nameObj[i18n.language] || nameObj["en"] || "N/A";
        setProducts([
          {
            key: product.id,
            id: product.id,
            name: name,
            Stock: stock.stock,
            image_url: `https://samhome-iq.com/api/storage/${
              stock.files[0]?.path ?? product.image_url
            }`,

            Sku: stock.sku,
          },
        ]);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setProducts([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSearch = (value) => {
    fetchProducts(value);
  };

  const updateStock = (sku, newValue) => {
    console.log("Ssssssss" + sku);
    const url = `https://api.samhome-iq.com/api/products/${sku}/store/${Cookies.get(
      "store"
    )}`;
    axios
      .put(url, { value: newValue })
      .then((response) => {
        console.log("Stock updated successfully" + response.data);
        // Update the local state with the new stock value
        const updatedProducts = products.map((product) => {
          if (product.id === sku) {
            return { ...product, Stock: newValue };
          }
          return product;
        });
        setProducts(updatedProducts);
        fetchProducts(sku);

        // Save the updated products JSON to localStorage
        localStorage.setItem(
          "updatedProducts",
          JSON.stringify(updatedProducts)
        );
      })
      .catch((error) => {
        console.error("Error updating stock:", error);
      });
  };

  const ModalContent = ({ item }) => {
    console.log(item);
    const [inputValue, setInputValue] = useState(0);

    const handleIncrement = () => {
      updateStock(item.Sku, +inputValue);
      setModalVisible(false); // Close the modal
    };

    const handleDecrement = () => {
      updateStock(item.Sku, -inputValue);
      setModalVisible(false); // Close the modal
    };

    const handleInputChange = (value) => {
      setInputValue(value);
    };

    return (
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        width={"100%"}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={item.image_url}
            alt="product"
            height={90}
            width={90}
            onClick={() => {
              setModalVisible(true);
            }}
          />
          <div> Sku : {item.Sku}</div>
          <div> Stock : {item.Stock}</div>
          <Form>
            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
              <InputNumber value={inputValue} onChange={handleInputChange} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
              <Button type="primary" onClick={handleIncrement}>
                +
              </Button>
              <span> </span>
              <Button type="primary" onClick={handleDecrement}>
                -
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <Input.Search
        placeholder={t("search")}
        style={{ textAlign: "center" }}
        onSearch={onSearch}
        enterButton
        onChange={(e) => {
          if (e.target.value === "") {
            setProducts([]);
          }
        }}
      />
      <hr />

      <List
        itemLayout="horizontal"
        dataSource={products}
        loading={loading}
        renderItem={(item) => (
          <List.Item>
            <img
              src={item.image_url}
              alt="product"
              height={120}
              width={120}
              onClick={() => {
                setModalVisible(true);
              }}
            />
            <span className="detail-label"></span>
            <div className="product-info">
              Name: {item.name}
              <br />
              Sku : {item.Sku}
              <br />
              Stock : {item.Stock}
              <br />
            </div>
            <ModalContent item={item} />
          </List.Item>
        )}
      />
    </div>
  );
};

export default Stock;

// import React, { useEffect, useState } from "react";
// import { List, Input, Modal, Form, InputNumber, Button } from "antd";
// import { useTranslation } from "react-i18next";
// import "./styles/AllProducts.css";
// import axios from "axios";

// const Stock = () => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [inputValue, setInputValue] = useState(0);
//   const [modalVisible, setModalVisible] = useState(false);
//   const { i18n } = useTranslation();

//   const fetchProducts = (sku) => {
//     setLoading(true);
//     let url;
//     if (sku === "") {
//       url = `https://api.samhome-iq.com/api/products/store/7`;
//     } else {
//       url = `https://api.samhome-iq.com/api/products/${sku}/store/7`;
//     }
//     axios
//       .get(url)
//       .then((response) => {
//         const product = response.data[0];
//         const stock = product.stocks.find((stock) => stock.sku === sku);
//         const nameObj = JSON.parse(product.name);
//         const name = nameObj[i18n.language] || nameObj["en"] || "N/A";
//         setProducts([
//           {
//             key: product.id,
//             id: product.id,
//             name: name,
//             Stock: stock.stock,
//             image_url: `https://samhome-iq.com/api/storage/${stock.files[0].path}`,
//             Sku: stock.sku,
//           },
//         ]);
//       })
//       .catch((error) => {
//         console.error("Error fetching products:", error);
//         setProducts([]);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     fetchProducts("");
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [i18n.language]);

//   const onSearch = (value) => {
//     fetchProducts(value);
//   };

//   const updateStock = (sku, newStock) => {
//     const url = `https://api.samhome-iq.com/api/products/${sku}/store/7`;
//     axios
//       .put(url, { stock: newStock })
//       .then(() => {
//         fetchProducts(sku); // Fetch updated products
//       })
//       .catch((error) => {
//         console.error("Error updating stock:", error);
//       });
//   };

//   const handleIncrement = () => {
//     if (inputValue > 0) {
//       const newStock = inputValue + 1;
//       updateStock(selectedProduct.sku, newStock);
//     }
//     setModalVisible(false); // Close the modal
//   };

//   const handleDecrement = () => {
//     if (inputValue > 0) {
//       const newStock = inputValue - 1;
//       updateStock(selectedProduct.sku, newStock);
//     }
//     setModalVisible(false); // Close the modal
//   };

//   const handleInputChange = (value) => {
//     setInputValue(value);
//   };

//   return (
//     <div>
//       <Input.Search
//         placeholder="input search text"
//         onSearch={onSearch}
//         enterButton
//         onChange={(e) => {
//           if (e.target.value === "") {
//             setProducts([]);
//           }
//         }}
//       />
//       <hr />

//       <List
//         itemLayout="horizontal"
//         dataSource={products}
//         loading={loading}
//         renderItem={(item) => (
//           <List.Item>
//             <img
//               src={item.image_url}
//               alt="product"
//               height={120}
//               width={120}
//               onClick={() => {
//                 setSelectedProduct(item);
//                 setInputValue(item.Stock);
//                 setModalVisible(true);
//               }}
//             />
//             <span className="detail-label"></span>
//             <div className="product-info">
//               Name: {item.name}
//               <br />
//               SKU: {item.Sku}
//               <br />
//               Stock: {item.Stock}
//               <br />
//             </div>
//           </List.Item>
//         )}
//       />
//       <Modal
//         open={modalVisible}
//         // onCancel={() => setModalVisible(false)}
//         footer={null}
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//         width={"100%"}
//       >
//         <div>
//           <img
//             src={selectedProduct?.image_url}
//             alt="product"
//             height={150}
//             width={150}
//           />

//           <div>SKU: {selectedProduct?.Sku}</div>
//           <div>Stock: {selectedProduct?.Stock}</div>

//           <Form>
//             <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
//               <InputNumber value={inputValue} onChange={handleInputChange} />
//             </Form.Item>

//             <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
//               <Button type="primary" onClick={handleIncrement}>
//                 +
//               </Button>
//               <Button type="primary" onClick={handleDecrement}>
//                 -
//               </Button>
//             </Form.Item>
//           </Form>
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default Stock;
