import React from "react";
import ImageNotFound from "./images/notFound.png";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90vh",
        padding: "20px",
      }}
    >
      <img
        src={ImageNotFound}
        height={300}
        width={300}
        alt="404"
        style={{ marginBottom: "15px" }}
      />
      <div style={{ textAlign: "center", maxWidth: "400px" }}>
        <h1 style={{ fontSize: "2em", marginBottom: "10px", color: "#333" }}>
          404 - Page Not Found
        </h1>
        <p style={{ color: "#666", marginBottom: "20px" }}>
          We're sorry, but the page you requested could not be found.
        </p>
        <p>
          Return to{" "}
          <a href="/" style={{ color: "#1890ff", textDecoration: "underline" }}>
            Dashboard
          </a>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
