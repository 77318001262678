import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
// import Lang from "../Pages/Lang";
import LangTwo from "../Pages/LangTwo";
import DashboardIcon from "@mui/icons-material/Menu";
import Minu from "../Pages/minu";
import DarkMode from "../Pages/DarkMode";
import dashboard from "./images/dashboard.png";
import stock from "./images/products.png";
import product from "./images/in-stock.png";
import currencies from "./images/currency-conversion.png";
import profile from "./images/profile.png";
import logOut from "./images/log-out.png";
import Logo from "./images/Logo.png";
import Facebook from "./images/facebook.jpg";
import WhatSApp from "./images/what is up.jpg";
import { useTranslation } from "react-i18next";
import "./Style/Navbar.css";
const Navbar = () => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ backgroundColor: "#f0f0f0", padding: "20px" }}>
        <img
          src={Logo}
          alt="logo"
          style={{ width: "120px", height: "auto", marginBottom: "10px" }}
        />
        <ListItem button key="Dashboard" disablePadding>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={dashboard}
              alt="Dashboard"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary={t("dashboard")} />
          </Link>
        </ListItem>
        <ListItem button key="Allproducts" disablePadding>
          <Link
            to="/Allproducts"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={product}
              alt="Allproducts"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary={t("Allproducts")} />
          </Link>
        </ListItem>
        <ListItem button key="currencies" disablePadding>
          <Link
            to="/currencies"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={currencies}
              alt="currencies"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary={t("currencies")} />
          </Link>
        </ListItem>
        <ListItem button key="stock" disablePadding>
          <Link
            to="/stock"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={stock}
              alt="stock"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary={t("stock")} />
          </Link>
        </ListItem>
        {[
          // { icon: dashboard, text: t("dashboard") },
          // { icon: product, text: t("Allproducts") },
          // { icon: currencies, text: t("currencies") },
          // { icon: stock, text: t("stock") },
          { text: "LangTwo" },
        ].map(({ icon, text }) => (
          <ListItem button key={text} disablePadding>
            {icon && <img src={icon} alt="icon" width={24} height={24} />}
            <span style={{ marginLeft: "10px" }}>
              {text === "LangTwo" ? (
                <LangTwo />
              ) : (
                <Link
                  to={`/${text}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItemText primary={text} />
                </Link>
              )}
            </span>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{ backgroundColor: "#f0f0f0", padding: "30px" }}>
        {[
          {
            icon: (
              <img
                src={profile}
                alt="icon"
                width={24}
                height={24}
                style={{ marginRight: "10px" }}
              />
            ),
            text: t("profile"),
            link: "/Profile",
          },
          {
            icon: (
              <img
                src={logOut}
                alt="icon"
                width={24}
                height={24}
                style={{ marginRight: "10px" }}
              />
            ),
            text: t("logout"),

            onClick: () => {
              Cookies.remove("user");
              Cookies.remove("store");
              window.location.href = "/";
            },
          },
        ].map(({ icon, text, link, onClick }) => (
          <ListItem button key={text} disablePadding onClick={onClick}>
            {icon}
            <span style={{ marginLeft: "10px" }}>
              {link ? (
                <Link
                  to={link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItemText primary={text} />
                </Link>
              ) : (
                text
              )}
            </span>
          </ListItem>
        ))}
        <br />
        <DarkMode />
        {/* Add the new HTML content here */}
        <ul className="navbar-info">
          <li>{t("This website was created by")}</li>
          <li>
            <img
              src={Facebook}
              alt="Facebook"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <a
              href="https://www.facebook.com/TechTopia1998"
              target="_blank"
              rel="noopener noreferrer"
            >
              TechTopia
            </a>
          </li>
          <li>
            <img
              src={WhatSApp}
              alt="WhatsApp"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <a
              href="https://wa.link/1o8y3b"
              target="_blank"
              rel="noopener noreferrer"
            >
              07811421542
            </a>
          </li>
        </ul>
      </List>
    </Box>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              variant="contained"
              color="success"
              onClick={toggleDrawer(anchor, true)}
              style={{ marginRight: "50px", borderRadius: 8 }}
              startIcon={<DashboardIcon />}
            >
              {anchor}
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ "& .MuiDrawer-paper": { width: "250px" } }}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      <Minu />
    </div>
  );
};

export default Navbar;
