import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { MailOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import Lang from "../Pages/Lang";
import "./styles/login.css";
import DarkMode from "../Pages/DarkMode";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const Login = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    const URI = "https://samhome-iq.com/api/login-store";
    const requestBody = {
      email,
      password,
    };
    // console.log("Login request initiated with:", requestBody);
    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        // console.log("Response received:", res);
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        message.success("Login successful");
        // console.log("Login successful, data received:", data);

        Cookies.set("user", JSON.stringify(data["user"]["id"]));
        Cookies.set("store", JSON.stringify(data["store"]["id"]));

        window.location.href = "/";
      })
      .catch((error) => {
        message.error(t("InvalidLogin"));
      });
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };

  const direction = ["ar", "ku", "fa"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku", "fa"].includes(i18n.language)
    ? "right"
    : "left";

  return (
    <div>
      <div className="login-container">
        <DarkMode />
        <Lang />
      </div>
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ textAlign, direction }}
        >
          <div className="login-form-title">{t("signinWithEmail")}</div>
          <Form.Item
            name={"email"}
            label={<span className="form-label">{t("email")}</span>}
            rules={[
              {
                type: "email",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<MailOutlined />}
            />
          </Form.Item>

          <Form.Item
            label={<span className="form-label">{t("password")}</span>}
            name="password"
            rules={[
              {
                required: true,
                message: "Password is invalid",
              },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              onClick={handleLogin}
            >
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
