import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Upload,
  Checkbox,
  Switch,
  TreeSelect,
  Select,
} from "antd";
import { IconButton, Box } from "@mui/material";
import {
  DeleteOutlined,
  SaveFilled,
  HomeFilled,
  BgColorsOutlined,
} from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import videoIcon from "./images/video.png";
// import imagesIcon from "./images/gallery.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { useForm } from "react-hook-form";
// import { UploadOutlined } from "@mui/icons-material";
const ProductID = ({ storeData }) => {
  const { TextArea } = Input;
  const onChange = (e) => {
    // console.log("Change:", e.target.value);
  };
  const { register, setValue } = useForm();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { id } = useParams();
  // const product = storeData[id];
  // const product = storeData.find((item) => item.id === parseInt(id));
  const product = Array.isArray(storeData)
    ? storeData.find((item) => item.id === parseInt(id))
    : null;
  const [image_url, setImage_url] = useState(null);
  const [video_url, setVideo_url] = useState(null);

  const [form] = Form.useForm();

  const [colors, setColors] = useState([
    // const [colors] = useState([
    
    { hex: "0xFF000000", color: "Black", isSelected: false },
    { hex: "0xFFFFFFFF", color: "White", isSelected: false },
    { hex: "0xFF6B4423", color: "Brown Wooden", isSelected: false },
    { hex: "0xFF8B4513", color: "Saddle Brown", isSelected: false },
    { hex: "0xFFA0522D", color: "Sienna", isSelected: false },
    { hex: "0xFF808080", color: "Slate Grey", isSelected: false },
    { hex: "0xFF8B7D6B", color: "Khaki", isSelected: false },
    { hex: "0xFFCD853F", color: "Peru", isSelected: false },
    { hex: "0xFF2E8B57", color: "Sea Green", isSelected: false },
    { hex: "0xFF556B2F", color: "Dark Olive Green", isSelected: false },
    { hex: "0xFFBDB76B", color: "Dark Khaki", isSelected: false },
    { hex: "0xFF808000", color: "Olive", isSelected: false },
    { hex: "0xFFD2691E", color: "Chocolate", isSelected: false },
    { hex: "0xFF696969", color: "Dim Grey", isSelected: false },
    { hex: "0xFFDCDCDC", color: "Light Grey", isSelected: false },
    { hex: "0xFFDCDCDC", color: "Gainsboro", isSelected: false },
    { hex: "0xFFD2B48C", color: "Tan", isSelected: false },
    { hex: "0xFF4B0082", color: "Indigo", isSelected: false },
    { hex: "0xFF483D8B", color: "Dark Slate Blue", isSelected: false },
    { hex: "0xFF2F4F4F", color: "Dark Slate Grey", isSelected: false },
    { hex: "0xFF000080", color: "Navy", isSelected: false },
    { hex: "0xFF800000", color: "Maroon", isSelected: false },
    { hex: "0xFF008080", color: "Teal", isSelected: false },
    { hex: "0xFF006400", color: "Dark Green", isSelected: false },
    { hex: "0xFF8B0000", color: "Dark Red", isSelected: false },
    { hex: "0xFFCD5C5C", color: "Indian Red", isSelected: false },
    { hex: "0xFFBC8F8F", color: "Rosy Brown", isSelected: false },
    { hex: "0xFFFA8072", color: "Salmon", isSelected: false },
    { hex: "0xFF32CD32", color: "Lime Green", isSelected: false },
    { hex: "0xFF7FFFD4", color: "Aquamarine", isSelected: false },
    { hex: "0xFF4682B4", color: "Steel Blue", isSelected: false },
    { hex: "0xFF20B2AA", color: "Light Sea Green", isSelected: false },
    { hex: "0xFF00FA9A", color: "Medium Spring Green", isSelected: false },
    { hex: "0xFF8A2BE2", color: "Blue Violet", isSelected: false },
    { hex: "0xFFD8BFD8", color: "Thistle", isSelected: false },
    { hex: "0xFFB0C4DE", color: "Light Steel Blue", isSelected: false },
    { hex: "0xFF8FBC8B", color: "Dark Sea Green", isSelected: false },
    { hex: "0xFFB0E0E6", color: "Powder Blue", isSelected: false },
    { hex: "0xFFC71585", color: "Medium Violet Red", isSelected: false },
    { hex: "0xFF556B2F", color: "Olive Drab", isSelected: false },
    { hex: "0xFFA52A2A", color: "Brown", isSelected: false },
    { hex: "0xFF8B008B", color: "Dark Magenta", isSelected: false },
    { hex: "0xFFB8860B", color: "Dark Goldenrod", isSelected: false },
    { hex: "0xFFDAA520", color: "Goldenrod", isSelected: false },
    { hex: "0xFF9400D3", color: "Dark Violet", isSelected: false },
    { hex: "0xFFD2691E", color: "Dark Orange", isSelected: false },
    { hex: "0xFFB22222", color: "Fire Brick", isSelected: false },
    { hex: "0xFFFF0000", color: "Red", isSelected: false },
    { hex: "0xFF00FF00", color: "Green", isSelected: false },
    { hex: "0xFF0000FF", color: "Blue", isSelected: false },
    { hex: "0xFFFFFF00", color: "Yellow", isSelected: false },
    { hex: "0xFFFFA500", color: "Orange", isSelected: false },
    { hex: "0xFF800080", color: "Purple", isSelected: false },
    { hex: "0xFFFFC0CB", color: "Pink", isSelected: false },
  ]);
  //todo ======================================================================================
  //todo ==============Get products/store   ==========================================
  useEffect(() => {
    if (product) {
      const imageUrl = product.image_url ? `${product.image_url}` : null;
      // console.log("imageUrl 111:", imageUrl);
      setImage_url(imageUrl);
      const videoUrl = product.video_url ? `${product.video_url}` : null;
      // console.log("videoUrl 222:", videoUrl);
      setVideo_url(videoUrl);
      const name = JSON.parse(product.name || "{}");
      form.setFieldsValue({
        english: name.en || "",
        arabic: name.ar || "",
        kurdish: name.ku || "",
        turkish: name.tr || "",
        farsi: name.fa || "",
      });
      const description = JSON.parse(product.description || "{}");
      form.setFieldsValue({
        englishAddress: description.en || "",
        arabicAddress: description.ar || "",
        kurdishAddress: description.ku || "",
        turkishAddress: description.tr || "",
        farsiAddress: description.fa || "",
      });
      form.setFieldsValue({
        price: product.price || "",
        discounted_price: product.discounted_price || "",
        currency: JSON.parse(product.currency.name)[i18n.language || "en"],
        category: JSON.parse(product.category.name)[i18n.language || "en"],
        active: product.active === 1,
      });
      //todo ===this is stocks ======================================
      if (product.stocks) {
        setStocks(product.stocks);

        product.stocks.forEach((e) => {
          console.log(e);

          const color = colors.find((c) => c.hex === e.hex);
          if (color) {
            color.isSelected = true;
            console.log(color);
          }

          setFiles(e.files);
        });

        console.log(colors);
      }
    }
  }, [form, product, i18n.language]);
  //!==================================================================================================
  //todo ===========colors=================================
  //todo -----------this is colors ----------------
  const [isModalVisibleColors, setIsModalVisibleColors] = useState(false);
  // const [showAdditionalInput] = useState([]);
  const [stocks, setStocks] = useState([]);
  const showModalColors = () => {
    setIsModalVisibleColors(true);
  };

  const handleOkColors = () => {
    setIsModalVisibleColors(false);
  };

  const handleCancelColors = () => {
    setIsModalVisibleColors(false);
  };
  //   useEffect(() => {
  //   setStocks((prevStocks) => prevStocks.filter((stock) => stock.color !== null && stock.hex !== null));
  // }, [stocks]);

  const handleStockChange = (index, field, value) => {
    setStocks((prevStocks) => {
      const newStocks = [...prevStocks];
      newStocks[index] = {
        ...newStocks[index],
        [field]: value,
      };
      return newStocks;
    });
  };

  const handleChangeColors = (indexColor) => {
    const updatedColors = [...colors];
    updatedColors[indexColor].isSelected =
      !updatedColors[indexColor].isSelected;

    const selectedColor = updatedColors[indexColor];

    setColors(updatedColors);

    setStocks((prevStocks) => {
      let newStocks;
      if (selectedColor.isSelected) {
        newStocks = [
          ...prevStocks,
          {
            color: selectedColor.color,
            hex: selectedColor.hex,
            sku: "",
            stock: "1",
            files: [],
          },
        ];
      } else {
        newStocks = prevStocks.filter(
          (stock) => stock.hex !== selectedColor.hex
        );
      }

      // Remove invalid entries
      newStocks = newStocks.filter(
        (stock) => stock.color !== null && stock.hex !== null
      );

      if (newStocks.length === 0) {
        newStocks = [
          {
            color: null,
            hex: null,
            sku: "",
            stock: "1",
            files: [],
          },
        ];
      }

      return newStocks;
    });
  };



  useEffect(() => {
    console.log("Updated stocks:", stocks);
  }, [stocks]);
  //todo
  const hexToRgb = (hex) => {
    // Function to convert hex to rgb
    let bigint = parseInt(hex.replace("0x", ""), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };

  //todo add multeple  image
  const [files, setFiles] = useState([]);


  const handleFileChange = (e, index) => {
    const selectedFiles = Array.from(e.target.files);

    const updatedStocks = [...stocks]; // Create a copy of the stocks array

    updatedStocks[index] = {
      ...updatedStocks[index],
      files: [...updatedStocks[index].files, ...selectedFiles], // Add selectedFiles to the existing files array
    };

    setStocks(updatedStocks);

    setValue(`upload${index}`, selectedFiles); // Set value for react-hook-form
  };

  const handleFileRemove = (fileIndex, colorIndex) => {
    const updatedStocks = [...stocks]; // Create a copy of the stocks array

    const newFiles = updatedStocks[colorIndex].files.filter(
      (_, i) => i !== fileIndex
    );

    updatedStocks[colorIndex] = {
      ...updatedStocks[colorIndex],
      files: newFiles,
    };

    setStocks(updatedStocks);

    // setValue(`upload${colorIndex}`, newFiles);
  };

  //! ==========end color==========================
  //Todo this is name story ----------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
  };
  //todo this is End =========================
  //todo this is Modal Store Address
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = async () => {
    setIsModalOpen2(false);
  };
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const handleDelete = () => {
    setIsDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://api.samhome-iq.com/api/products/${id}`);
      // Add any additional actions after successful deletion
      setIsDeleteModalVisible(false);
      window.location.href = "/";
    } catch (error) {
      console.error("Error deleting product:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };
  //! todo --------create Delete -------------
  //todo this is Image ============================================================
  const handleImageChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      //todo ======
      if (file.status != "uploading") {
        // console.log("trying to upload image");
        const response = await axios.post(
          "https://api.samhome-iq.com/api/upload-file",
          formData
        );
        // console.log("response from image");
        console.log(response.data);
        if (response.status === 200) {
          const imageUrl = `${response.data.path}`;
          setImage_url(imageUrl);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  // const FileImage = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList ? [e.file] : [];
  // };
  const FileImage = (e) => {
    // console.log('Upload event:', e);
    return e && e.fileList ? [e.file] : [];
  };
  const beforeUploadImage = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setImage_url(null);
    } else {
      await handleImageChange(file.originFileObj);
    }
  };
  //! end image =============================================================
  //todo this is video======================================
  const handleVideoChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file.originFileObj);
      console.log("video here");
      //todo here ------
      if (file.status != "uploading") {
        console.log(file);

        const response = await axios.post(
          "https://api.samhome-iq.com/api/upload-file",
          formData
        );
        console.log("video here 2222222");
        if (response.status === 200) {
          setVideo_url(response.data.path);
        }
      }
    } catch (error) {
      console.error("Error uploading Video:", error);
    }
  };
  // const acceptVideoTypes = ["video/mp4", "video/x-msvideo", "video/quicktime"];
  const FileVideo = (e) => {
    // if (Array.isArray(e)) {
    //   return e;
    // }
    // return e && e.fileList ? [e.file] : [];
    return e && e.fileList ? [e.file] : [];
  };
  const beforeUploadVideo = async ({ file, fileList }) => {
    // const isVideo = acceptVideoTypes.includes(file.type);
    // console.log("video is upload ?", file);
    // if (!isVideo) {
    await handleVideoChange(file);
    // message.error("You can only upload video files!");
    // return Promise.reject("Only video files are allowed");
    // }
    // return true; // Allow the upload of video files
  };
  //! this is video=============================
  //todo ==================================================================================================
  function findCategoryId(categories, categoryName) {
    for (const category of categories) {
      const parsedName = JSON.parse(category.name);
      if (
        parsedName.en === categoryName ||
        parsedName.ar === categoryName ||
        parsedName.ku === categoryName ||
        parsedName.tr === categoryName ||
        parsedName.fa === categoryName
      ) {
        return category.id;
      }

      if (category.subcategories && category.subcategories.length > 0) {
        const categoryId = findCategoryId(category.subcategories, categoryName);
        if (categoryId) {
          return categoryId;
        }
      }
    }
  }
  //todo ==================================================================================================

  //todo ==================================================================================================
  const onFinish = async (values) => {
    // console.log("Success:", values);
    // console.log("Uploaded files:", files);
    try {
      await Promise.all(
        stocks.map(async (stock, stockIndex) => {
          const fileUploadPromises = stock.files.map(
            async (image, fileIndex) => {
              console.log(":image", image);

              if (image.id != null) return image;
              try {
                const fileData = new FormData();
                fileData.append("file", image);
                const response = await axios.post(
                  "https://api.samhome-iq.com/api/upload-file",
                  fileData
                );
                return response.data; // Return the file path
              } catch (error) {
                console.error("Error uploading file:", error);
                throw error; // Propagate error to stop the submission
              }
            }
          );

          const uploadedFiles = await Promise.all(fileUploadPromises);
          stocks[stockIndex].images = uploadedFiles; // Update the files array with uploaded file paths

          console.log(uploadedFiles);
          console.log(stocks);
        })
      );

      const name = {
        en: form.getFieldValue("english"),
        ar: form.getFieldValue("arabic"),
        ku: form.getFieldValue("kurdish"),
        tr: form.getFieldValue("turkish"),
        fa: form.getFieldValue("farsi"),
      };
      const description = {
        en: form.getFieldValue("englishAddress"),
        ar: form.getFieldValue("arabicAddress"),
        ku: form.getFieldValue("kurdishAddress"),
        tr: form.getFieldValue("turkishAddress"),
        fa: form.getFieldValue("farsiAddress"),
      };
      if (typeof values.currency === "string") {
        values.currency = dataCurrencies.find(
          (currency) => currency.code === values.currency
        ).id;
      }
      if (typeof values.category === "string") {
        values.category = findCategoryId(data, values.category);
      }
      console.log("data");
      console.log({
        name: name,
        description: description,
        image_url: image_url,
        video_url: video_url,
        price: values.price,
        discounted_price: values.discounted_price,
        active: values.active,
        category_id: values.category,
        currency_id: values.currency,
        stocks: stocks,
      });
      const updatedStocks = stocks.map(({ files, ...rest }) => ({
        id: rest.id,
        sku: rest.sku,
        stock: rest.stock,
        color: rest.color,
        hex: rest.hex,
        files: JSON.stringify(rest.images),
      }));

      let stringifiedStocks = JSON.stringify(updatedStocks);

      console.log("stocks::: ", updatedStocks);

      // Update product details
      const productUpdateResponse = await axios.put(
        `https://api.samhome-iq.com/api/products/${id}`,
        {
          name: name,
          description: description,
          image_url: image_url,
          video_url: video_url,
          price: values.price,
          discounted_price: values.discounted_price,
          active: values.active,
          category_id: values.category,
          currency_id: values.currency,
          stocks: stringifiedStocks,
        }
      );
      // Handle category and currency changes
      console.log("Product update response:", productUpdateResponse.data);
      message.success(t("ProductUpdated"));
      window.location.href = "/Allproducts";
    } catch (error) {
      console.error("Error updating product details:", error);
      message.error("Failed to update product details. Please try again.");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Failed to save profile information");
  };
  //todo -----------Api for currencies----------------
  const [dataCurrencies, setDataCurrencies] = useState([]);
  useEffect(() => {
    fetch("https://samhome-iq.com/api/currencies")
      .then((response) => response.json())
      .then((data) => {
        setDataCurrencies(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //todo -----------Api for categories----------------
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("https://samhome-iq.com/api/categories")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
      });
  }, []);

  const formatTreeData = (categories) => {
    return categories.map((category) => {
      const hasChildren =
        category.subcategories && category.subcategories.length > 0;
      return {
        title: (
          <span
            style={{ display: "flex", alignItems: "center", color: "black" }}
          >
            {category.image_url && (
              <img
                src={`https://samhome-iq.com/api/storage/${category.image_url}`}
                alt={category.name}
                style={{ width: 50, marginRight: 8 }}
              />
            )}
            {JSON.parse(category.name)[i18n.language || "en"]}
          </span>
        ),
        value: category.id,
        key: category.id,
        children: hasChildren
          ? formatTreeData(category.subcategories)
          : undefined,
        disabled: hasChildren, // Disable the node if it has children
      };
    });
  };
  //Todo ------------------------------------------------------------------
  const direction = ["ar", "ku", "fa"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku", "fa"].includes(i18n.language)
    ? "right"
    : "left";
  return (
    <div>
      {product && (
        <div>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 800,
              margin: "0 auto" /* Add this line to center the form */,
              direction,
              textAlign,
            }}
            initialValues={{
              isSelected: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div
              style={{ marginBottom: 20, fontSize: 20, textAlign: "center" }}
            >
              <div className="login-form-title">{t("UpdateProduct")}</div>
              {/* {t("UpdateProduct")} */}
            </div>
            {/* //TODO Delete button */}
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="danger"
                onClick={handleDelete}
                style={{
                  marginBottom: "16px",
                  backgroundColor: "#ff6347",
                  borderColor: "#ff6347",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t("delete")}
                <DeleteOutlined />
              </Button>

              {/* Delete confirmation modal */}
              <Modal
                title="Confirm Deletion"
                open={isDeleteModalVisible}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="OK"
                cancelText="Cancel"
              >
                <p>Are you sure you want to delete this product?</p>
              </Modal>
            </Form.Item>
            {/* //! END Delete Boutton */}
            {/* //todo this is Upload */}

            <Form.Item
              name="image"
              label={<span className="form-label">{t("image")}</span>}
              valuePropName="fileList"
              getValueFromEvent={FileImage}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Upload
                name="file"
                onChange={beforeUploadImage}
                // onClick={onChangeUpload}
                maxCount={1}

                // listType="picture"
              >
                {image_url ? (
                  <img
                    src={"https://api.samhome-iq.com/storage/" + image_url}
                    width={120}
                    height={120}
                    style={{
                      borderRadius: "30px",
                    }}
                    alt="Store"
                  />
                ) : (
                  <img
                    src={`https://api.samhome-iq.com/storage/placeholders/product.jpg`}
                    // src={imagesIcon}
                    width={120}
                    height={120}
                    style={{
                      borderRadius: "30px",
                    }}
                    alt="Store"
                  />
                )}
              </Upload>
            </Form.Item>
            {/* //todo this is video */}
            <Form.Item
              name="file"
              label={<span className="form-label">{t("video")}</span>}
              valuePropName="fileList"
              getValueFromEvent={FileVideo}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Upload
                name="file"
                // beforeUpload={beforeUploadVideo}
                onChange={beforeUploadVideo}
                // action="/upload.do"
                maxCount={1}
              >
                {video_url ? (
                  <video
                    src={"https://api.samhome-iq.com/storage/" + video_url}
                    width={120}
                    height={120}
                    style={{
                      borderRadius: "30px",
                    }}
                    alt="Store"
                  />
                ) : (
                  <img
                    src={videoIcon}
                    // src={"https://api.samhome-iq.com/storage/"+video_url}
                    width={120}
                    height={120}
                    style={{
                      borderRadius: "30px",
                    }}
                    alt="Store"
                  />
                )}
              </Upload>
            </Form.Item>
            {/* //! this is video */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 60,
                borderRadius: "4px", // Add border radius
              }}
            >
              <Form.Item name="name">
                <Button
                  type="text"
                  style={{
                    width: "200px", // Set the width to 200px
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "6px 10px",
                    display: "inline-block",

                    textAlign: "center", // Center align text
                    backgroundColor: "#f0f0f0", // Change background color
                    color: "#333", // Change text color
                    fontWeight: "bold", // Make text bold
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", // Add a box shadow
                  }}
                  onClick={showModal}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("name")}
                    <DownOutlined />
                  </div>
                </Button>
              </Form.Item>

              <Modal
                title="Store Information"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Form.Item name="english" label={t("english")}>
                  <Input
                    placeholder={t("english")}
                    value={product.name ? JSON.parse(product.name).en : ""}
                  />
                </Form.Item>
                <Form.Item name="arabic" label={t("arabic")}>
                  <Input
                    placeholder={t("arabic")}
                    value={product.name ? JSON.parse(product.name).ar : ""}
                  />
                </Form.Item>
                <Form.Item name="kurdish" label={t("kurdish")}>
                  <Input
                    placeholder={t("kurdish")}
                    value={product.name ? JSON.parse(product.name).ku : ""}
                  />
                </Form.Item>
                <Form.Item name="turkish" label={t("turkish")}>
                  <Input
                    placeholder={t("turkish")}
                    value={product.name ? JSON.parse(product.name).tr : ""}
                  />
                </Form.Item>
                <Form.Item name="farsi" label={t("farsi")}>
                  <Input
                    placeholder={t("farsi")}
                    value={product.name ? JSON.parse(product.name).fa : ""}
                  />
                </Form.Item>
              </Modal>
              {/* //! todo ---end  name store */}
              {/* //TODO modal Store Address ----------------------------------------  */}
              <Form.Item name="description">
                <Button
                  type="text"
                  style={{
                    width: "200px", // Set the width to 200px
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "6px 10px",
                    display: "inline-block",

                    textAlign: "center", // Center align text
                    backgroundColor: "#f0f0f0", // Change background color
                    color: "#333", // Change text color
                    fontWeight: "bold", // Make text bold
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", // Add a box shadow
                  }}
                  onClick={showModal2}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("desc")}
                    <DownOutlined />
                  </div>
                </Button>
              </Form.Item>
              <Modal
                title="Basic Modal"
                open={isModalOpen2}
                onOk={handleOk2}
                onCancel={handleCancel2}
              >
                <Form.Item name="englishAddress" label={t("english")}>
                  <TextArea
                    showCount
                    maxLength={250}
                    onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={
                      product.description
                        ? JSON.parse(product.description).en
                        : ""
                    }
                  />
                </Form.Item>
                <Form.Item name="arabicAddress" label={t("arabic")}>
                  <TextArea
                    placeholder={t("arabic")}
                    showCount
                    maxLength={250}
                    onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={
                      product.description
                        ? JSON.parse(product.description).ar
                        : ""
                    }
                  />
                </Form.Item>
                <Form.Item name="kurdishAddress" label={t("kurdish")}>
                  <TextArea
                    showCount
                    placeholder={t("kurdish")}
                    maxLength={250}
                    onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={
                      product.description
                        ? JSON.parse(product.description).ku
                        : ""
                    }
                  />
                </Form.Item>
                <Form.Item name="turkishAddress" label={t("turkish")}>
                  <TextArea
                    showCount
                    placeholder={t("turkish")}
                    maxLength={250}
                    onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={
                      product.description
                        ? JSON.parse(product.description).tr
                        : ""
                    }
                  />
                </Form.Item>
                <Form.Item name="farsiAddress" label={t("farsi")}>
                  <TextArea
                    showCount
                    placeholder={t("farsi")}
                    maxLength={250}
                    onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={
                      product.description
                        ? JSON.parse(product.description).fa
                        : ""
                    }
                  />
                </Form.Item>
              </Modal>
              {/* //todo end ============================================================= */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 80,
                borderRadius: "4px", // Add border radius
              }}
            >
              <Form.Item
                label={<span className="form-label">{t("price")}</span>}
                name="price"
              >
                <InputNumber
                  style={{ width: "100%", paddingLeft: 20 }}
                  placeholder="0.0"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="form-label">{t("discountedPrice")}</span>
                }
                name="discounted_price"
              >
                <InputNumber
                  style={{ width: "100%", paddingLeft: 24 }}
                  placeholder="0.0"
                />
              </Form.Item>
            </div>
            {/* // todo this is Api for Currency */}
            <Form.Item
              label={<span className="form-label">{t("currency")}</span>}
              name="currency"
            >
              <Select placeholder="Select currency" style={{ width: "84%" }}>
                {dataCurrencies.map((currency) => (
                  <Select.Option key={currency.id} value={currency.id}>
                    {JSON.parse(currency.symbol)[i18n.language || "en"]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* // todo this is Api for Category */}
            <Form.Item
              label={<span className="form-label">{t("category")}</span>}
              name="category"
            >
              <TreeSelect
                showSearch
                style={{ width: "84%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={formatTreeData(data)}
                placeholder="Please select"
                treeDefaultExpandAll
              />
            </Form.Item>
            {/* //TODO modal ----------------------------------------  */}
            {/* //todo this is Switch */}
            <Form.Item
              name="active"
              label={<span className="form-label">{t("active")}</span>}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            {/* //! this is END Switch */}
            {/* //todo this is colors ================================================ */}
            {/* //todo this is color  */}
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  padding: "15px 30px",
                  fontSize: "16px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  transition: "background-color 0.3s",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={showModalColors}
              >
                {t("colors")}
                <BgColorsOutlined />
              </Button>
            </Form.Item>
            {/* //todo this is modal========================== */}
            <Modal
              title="Remember Me Details"
              open={isModalVisibleColors}
              onOk={handleOkColors}
              onCancel={handleCancelColors}
            >
              {colors.map((color, indexColor) => (
                <div
                  key={indexColor}
                  style={{
                    backgroundColor: color.hex,
                    padding: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Form.Item
                    name={color.hex}
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox
                      defaultChecked={color.isSelected}
                      onChange={() => handleChangeColors(indexColor)}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {color.color}
                        <span
                          style={{
                            display: "inline-block",
                            width: 25,
                            height: 25,
                            backgroundColor: hexToRgb(color.hex),
                            marginLeft: 5,
                            borderRadius: "50%",
                            border: "1px solid black",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)", // Optional: Add a shadow
                          }}
                        ></span>
                      </span>
                    </Checkbox>
                  </Form.Item>
                </div>
              ))}
            </Modal>
            {/* //todo this is stocks========================== */}
            {stocks.map((stock, index) => (
              <div key={index}>
                <div>
                  {stock.hex != null && (
                    <ul className="color-list">
                      <li className="login-form-title"> {t("color")} :</li>
                      <li
                        style={{
                          backgroundColor: hexToRgb(stock.hex),
                          padding: "5px",
                          borderRadius: "3px",
                          margin: "2px",
                        }}
                      >
                        {stock.color}
                      </li>
                    </ul>
                  )}
                  <Form.Item
                    label={<span className="form-label">SKU</span>}
                    style={{ marginBottom: "8px" }}
                  >
                    <Input
                      placeholder="SKU"
                      style={{ width: "84%" }}
                      value={stock.sku}
                      onChange={(e) =>
                        handleStockChange(index, "sku", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="form-label">{t("stock")}</span>}
                    style={{ marginBottom: 8 }}
                  >
                    <InputNumber
                      placeholder={t("stock")}
                      style={{ width: "84%", paddingLeft: 20 }}
                      value={stock.stock}
                      onChange={(value) =>
                        handleStockChange(index, "stock", value)
                      }
                    />
                  </Form.Item>
                  <div
                    style={{
                      marginBottom: 20,
                      fontSize: 20,
                      textAlign: "center",
                    }}
                  >
                    <div className="login-form-title">
                      {t("inputmultiple")} :
                    </div>
                    {/* {t("inputmultiple")} */}
                    {/* {stock.files[0].path} */}
                  </div>
                  {/* //todo  stock.files.foreach element render a image src html element showcasing the image and a remove button beside it to remove the image from stocks list   */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 16,
                    }}
                  >
                    <div style={{ position: "relative", width: "200px" }}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Click to upload
                      </Button>
                      <input
                        {...register(`image${index}`)}
                        type="file"
                        multiple
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          left: 0,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => handleFileChange(e, index)}
                      />
                    </div>
                  </div>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginBottom={2}
                  >
                    {stock.files.length > 0 &&
                      stock.files.map((file, fileIndex) => (
                        <Box
                          key={fileIndex}
                          display="flex"
                          alignItems="center"
                          marginBottom={1}
                        >
                          <img
                            src={`https://samhome-iq.com/api/storage/${file.path}`}
                            alt={`preview-${fileIndex}`}
                            style={{
                              width: 70,
                              height: 70,
                              objectFit: "cover",
                              marginRight: 8,
                            }}
                          />
                          {/* <h2 style={{ marginRight: 8 }}>{file.name}</h2> */}
                          <IconButton
                            onClick={() => handleFileRemove(fileIndex, index)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                  <hr
                    style={{
                      borderBottom: "2px solid black",
                    }}
                  />
                </div>
              </div>
            ))}

            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 12,
                xs: { span: 24 },
                sm: { span: 12, offset: 6 },
              }}
              style={{ textAlign: "center" }}
            >
              <Button
                type="primary"
                htmlType="submit"
              
                style={{
                  marginRight: "10px",
                  backgroundColor: "#f44336",
                  borderColor: "#f44336",
                }}
              >
                {t("save")}
                <SaveFilled />
              </Button>
              <Button
                type="primary"
                href="/"
                style={{ backgroundColor: "#2196f3", borderColor: "#2196f3" }}
              >
                {t("dashboard")}
                <HomeFilled />
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};
export default ProductID;
